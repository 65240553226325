import { BlockResult } from "./helpers/BlockResult";
// import { AverageRateOne, LoadLevelOne, PaycheckOne } from "../exports"

const BlockOne = (props) => {
  const { statDat, CustomBox } = props;

  return (
    <>
      <BlockResult statDat={statDat} CustomBox={CustomBox}/>
    </>
  );
};

export default BlockOne;
