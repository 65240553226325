import { AccountBalanceWallet } from '@mui/icons-material';
import StatControl from "../../HOC/StatControl";

export const PaycheckComplete = (props) => {
    const prep = {
        rgbaOne: `rgba(229, 139, 30, 0.58)`,
        rgbaTwo: `rgba(229, 139, 30, 0.11)`,
        textHeader: props.text,
        colorOfSecondBlock: "orange.main",
        symbol: "руб",
        textFooter: "Совокупный доход",
        preStatDat: props.income,
        ...props
    }
    return (
        <StatControl prep={prep}>
            <AccountBalanceWallet color='orange' sx={{ mr: 1 }} />
        </StatControl>
    )
}

