import "./singleReport.css"
import TitleBar from '../titleBar/TitleBar';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { ArrowBack, CheckCircle, Close, InsertChartOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

const PDFViewer = ({ show, handleClick, report, apartmentId }) => {
    const { DocumentURL } = report
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const transform = (slot) => ({
        ...slot,
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Open: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToPreviousPageMenuItem: () => <></>,
    });
    return (
        <>
            <TitleBar title='Отчеты'
                arrow={
                    <IconButton component={Link} to={`/apartments/${apartmentId}/reports`}>
                        <ArrowBack />
                    </IconButton>}
                icon={
                    <InsertChartOutlined color="primary" fontSize="large" sx={{ m: '0 10px 0' }} />
                }
            />
            <Box sx={{display: "flex", justifyContent: 'center', flexDirection: "column", flexGrow: "0", alignItems: "center"}}>
                <Button
                    variant='contained'
                    onClick={() => {
                        handleClick()
                    }}
                >Принять</Button>
                {show ? (
                    <Button 
                        sx={{position: "absolute", zIndex: 1, mt: 25, width: '250px', textTransform: 'none', bgcolor: 'rgba(226, 236, 245, 1)' }}
                        startIcon={<CheckCircle />}
                        endIcon={<Close />}
                        onClick={handleClick}
                    >
                        <Typography>Отчет принят</Typography>
                    </Button>
                ) : null}
            </Box>
            <div className="viewPdfDiv">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <div
                        style={{
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                        <Viewer plugins={[toolbarPluginInstance]} fileUrl={`${DocumentURL}`} defaultScale={SpecialZoomLevel.PageWidth} />
                    </div>
                </Worker>
                {/* <embed className="pdfete" src={DocumentURL} type="application/pdf" /> */}
            </div>
        </>
    );
}

export default PDFViewer;
