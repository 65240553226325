import BarChart from "../barChart/BarChart"

export const BarChartMap = ({age, stat}) => {
    
    const mappedCharts = stat.map((item) => {
        // console.log(age, item, stat);
        return(
            <BarChart stat={stat} />
        )
    })

    return(
        {mappedCharts}
    )
}
