import { DateRange } from '@mui/icons-material';
import StatControl from "../../HOC/StatControl";

export const OccupancyRate = (props) => {
    // console.log("AVERAGERATEONE", props.averageTarif);
    const prep = {
        rgbaOne: `rgba(77, 153, 168, 0.54)`,
        rgbaTwo: `rgba(105, 161, 172, 0.1)`,
        textHeader: "Загрузка",
        colorOfSecondBlock: "emerald.main",
        symbol: "%",
        textFooter: "Апартаменты были заняты",
        preStatDat: props.occupancy,
        ...props
    }
    return (
        <StatControl prep={prep}>
            <DateRange color='emerald' sx={{ mr: 1 }} />
        </StatControl>
    )
}